import handleSend from "./handleSend";
import { fetchAiBlurb } from "./fetchAiBlurb";
import {fetchGrowingJobsAi} from "./fetchGrowingJobsAi";

export async function prepareModalData(dispatch, data, careers) {
    // Fetch the AI-generated blurb using a separate function for cleanliness.
    const aiBlurb = await fetchAiBlurb(data.title);
    const jobClusters = await fetchGrowingJobsAi(data.title);
    // Build the new data object with enhanced clarity and structure.
    let newData = {
        id: data.id,
        title: data.title,
        message: `${data.title}! ${aiBlurb.message}`,
        buttons: jobClusters,
        hasButtons: true, // Simplified as the buttons array is statically populated above.,
        video: true
    };

    // Send the new data using the provided dispatch function.
    await handleSend(dispatch, newData, 'system');
}

