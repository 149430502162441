import { createSlice } from '@reduxjs/toolkit';

// Modal slice to manage the visibility of different screens within the application.
const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        currentScreen: null,
    },
    reducers: {
        setScreen: (state, action) => {
            state.currentScreen = action.payload; // Sets the current screen based on payload.
        },
        resetScreen: (state) => {
            state.currentScreen = null; // Resets the current screen to null.
        },
    },
});

// Export actions from the slices for use in components.
export const { setScreen, resetScreen } = modalSlice.actions;
export default modalSlice.reducer;