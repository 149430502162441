/** @format */

import React, { useState } from 'react';

const AccountMenu = (props) => {
	const initials = props.user ? props.user : "HT";
	const [open, setOpen] = useState(false);

	// TODO: pull in the image avatar
	return (
		<div className='account-menu-container dropdown'>
			<button
				className='button-unstyled'
				aria-expanded={open}
				onClick={() => setOpen(!open)}
			>
				<span className='avatar'>{initials}</span>
				<span className='icon-18'>
					<svg
						width='18'
						height='18'
						viewBox='0 0 18 18'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M5.99434 7H12.0057C12.5548 7 13 7.44827 13 8.00124C13 8.26845 12.8939 8.52458 12.7054 8.71261L9.69972 11.7101C9.31219 12.0966 8.68781 12.0966 8.30028 11.7101L5.29463 8.71261C4.90445 8.32349 4.90142 7.68955 5.28786 7.29667C5.47461 7.10681 5.72897 7 5.99434 7Z'
						/>
					</svg>
				</span>
			</button>
			<div className={open ? 'account-menu' : 'account-menu hidden'}>
				<ul>
					<li><a><span>My account</span></a></li>
					<li><a><span>Preferences center</span></a>
						{/* <label><input type='checkbox'/>Disable global email alerts</label> */}
					</li>
					<li><a><span>Reset password</span></a></li>
					<li><a><span>Help center</span></a></li>
					<li><a><span>Sign out</span></a></li>
				</ul>
			</div>
		</div>
	);
};

export default AccountMenu;
