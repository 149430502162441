export async function fetchGrowingJobsAi(copy) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/chat/jobs`;

    try {
        const response = await fetch(url, {
            method: 'POST',  // Ensuring it's a POST request
            headers: {
                'Content-Type': 'application/json'  // Specify that we're sending JSON
            },
            body: JSON.stringify({ message: copy })  // Sending the 'copy' as 'message' in the request body
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('THE DATA', await data)
        return data.message.jobs;
    } catch (error) {
        console.error('Failed to fetch AI recommended jobs:', error);
        return null;
    }
}
