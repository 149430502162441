import React, {useState, useEffect} from "react";
import botImage from "../../assets/images/bot.png";
import schoolPhoto from "../../assets/images/schoolPhoto.png";
import { useNavigate } from "react-router-dom";
import Header from "../shared/Header";
import { useSelector } from "react-redux";
import ToggleChip from "../shared/ToggleChip";

const placeholderUser = {
    firstName: 'Jason',
    lastName: 'Matthews',
    grade: '10th',
    extracurriculars: ['JV Lacrosse','Technology student association']
}
const interestSource = ["Building things", "Problem solving","Math","Science","Playing music","Debate","Sports","Helping others","Animals","Creating art","Research","Learning new things","Nature","Writing","Puzzles","Economics","History","Technology","Travel","World cultures","Language"];
const skillSource = [
    {text:"Communication",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18" fill="none"><path d="M22.375 13.0752H19.0825L18.3747 17.1739L16.0881 13.0752H7.42188V10.7314H16.3281V3.37207H22.375V13.0752Z" fill="#F7A36D"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.5781 0.27832V9.98145H6.91234L4.62531 14.0816L3.91703 9.98145H0.625V0.27832H15.5781ZM4.99839 6.44229C5.33439 6.10629 5.90894 6.34418 5.90894 6.81945C5.90894 7.11406 5.67016 7.35288 5.37555 7.35288C4.90028 7.35288 4.66239 6.77829 4.99839 6.44229ZM7.85392 6.44712C8.18823 6.1106 8.76255 6.34507 8.76573 6.81945C8.7677 7.11546 8.52831 7.35645 8.23234 7.35645C7.75797 7.35645 7.51966 6.78368 7.85392 6.44712ZM10.712 6.44229C11.048 6.10629 11.6225 6.34418 11.6225 6.81945C11.6225 7.11406 11.3837 7.35288 11.0891 7.35288C10.6139 7.35288 10.376 6.77829 10.712 6.44229Z" fill="#DD841B"/></svg>'},
    {text:"Critical thinking",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M13.7584 1.72656C9.0504 1.72656 5.25404 5.33212 5.01676 9.86656L2.61906 12.9588C2.31935 13.3377 2.61906 13.9488 3.14356 13.9488H5.01676V17.6155C5.01676 18.9721 6.1282 20.0599 7.51437 20.0599H8.76317V23.7266H17.5048V17.9943C20.4645 16.6255 22.5 13.7043 22.5 10.2821C22.5 5.56434 18.6037 1.72656 13.7584 1.72656ZM10.012 11.5043C9.32513 11.5043 8.76317 10.9543 8.76317 10.2821C8.76317 9.6099 9.32513 9.0599 10.012 9.0599C10.6988 9.0599 11.2608 9.6099 11.2608 10.2821C11.2608 10.9543 10.6988 11.5043 10.012 11.5043ZM13.7584 11.5043C13.0715 11.5043 12.5096 10.9543 12.5096 10.2821C12.5096 9.6099 13.0715 9.0599 13.7584 9.0599C14.4452 9.0599 15.0072 9.6099 15.0072 10.2821C15.0072 10.9543 14.4452 11.5043 13.7584 11.5043ZM17.5048 11.5043C16.8179 11.5043 16.256 10.9543 16.256 10.2821C16.256 9.6099 16.8179 9.0599 17.5048 9.0599C18.1916 9.0599 18.7536 9.6099 18.7536 10.2821C18.7536 10.9543 18.1916 11.5043 17.5048 11.5043Z" fill="#ED2385"/></svg>'},
    {text:"Teamwork",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path d="M23.4465 7.64936L2.21435 0.264266C2.00574 0.19175 1.7828 0.170188 1.56415 0.201378C1.34551 0.232568 1.13749 0.315607 0.957476 0.443562C0.777458 0.571517 0.630659 0.740677 0.529331 0.936921C0.428003 1.13316 0.375087 1.3508 0.375 1.57166V21.8807C0.375 22.2479 0.520888 22.6001 0.780571 22.8598C1.04025 23.1195 1.39246 23.2654 1.75971 23.2654C2.12695 23.2654 2.47916 23.1195 2.73884 22.8598C2.99852 22.6001 3.14441 22.2479 3.14441 21.8807V17.3262L23.4465 10.2642C23.7178 10.1695 23.9529 9.99279 24.1193 9.75853C24.2856 9.52428 24.375 9.24408 24.375 8.95676C24.375 8.66944 24.2856 8.38924 24.1193 8.15498C23.9529 7.92073 23.7178 7.74403 23.4465 7.64936ZM3.14441 14.394V3.51948L18.7766 8.95676L3.14441 14.394Z" fill="#C7ADCC"/><path d="M3.14441 14.394V3.51948L18.7766 8.95676L3.14441 14.394Z" fill="#A142B4"/></svg>'},
    {text:"Problem solving",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M4.625 6.72656C4.625 4.51742 6.41586 2.72656 8.625 2.72656H16.625C18.8341 2.72656 20.625 4.51742 20.625 6.72656V8.72656H4.625V6.72656Z" fill="#23C1ED"/><path d="M4.625 9.72656H20.625V15.7266H4.625V9.72656Z" fill="#60D2F1"/><path d="M4.625 16.7266H20.625V18.7266C20.625 20.9357 18.8341 22.7266 16.625 22.7266H8.625C6.41586 22.7266 4.625 20.9357 4.625 18.7266V16.7266Z" fill="#23C1ED"/></svg>'},
    {text:"Decision making",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><g clip-path="url(#clip0_418_38226)"><rect x="0.5" y="9.72656" width="6" height="6" rx="1" fill="#ED2385"/><rect x="17.5" y="2.72656" width="6" height="6" rx="1" fill="#ED2385"/><rect x="17.5" y="16.7266" width="6" height="6" rx="1" fill="#ED2385"/><rect x="7.5" y="11.7266" width="6" height="2" fill="#DEBECD"/><rect x="11.5" y="18.7266" width="12" height="2" transform="rotate(-90 11.5 18.7266)" fill="#DEBECD"/><path d="M16.5 6.72656L11.5 6.72656C11.5 5.62199 12.3954 4.72656 13.5 4.72656L16.5 4.72656L16.5 6.72656Z" fill="#DEBECD"/><path d="M11.5 18.7266H16.5V20.7266H13.5C12.3954 20.7266 11.5 19.8311 11.5 18.7266Z" fill="#DEBECD"/></g><defs><clipPath id="clip0_418_38226"><rect width="24" height="24" fill="white" transform="translate(0.5 0.726562)"/></clipPath></defs></svg>'},
    {text:"Time management",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M12.375 22.3266C14.9211 22.3266 17.3629 21.3151 19.1632 19.5148C20.9636 17.7144 21.975 15.2726 21.975 12.7266C21.975 10.1805 20.9636 7.73869 19.1632 5.93834C17.3629 4.13799 14.9211 3.12656 12.375 3.12656C9.82892 3.12656 7.38712 4.13799 5.58678 5.93834C3.78643 7.73869 2.775 10.1805 2.775 12.7266C2.775 15.2726 3.78643 17.7144 5.58678 19.5148C7.38712 21.3151 9.82892 22.3266 12.375 22.3266ZM12.375 0.726562C13.9509 0.726563 15.5113 1.03695 16.9672 1.64001C18.4231 2.24306 19.746 3.12698 20.8603 4.24128C21.9746 5.35558 22.8585 6.67845 23.4616 8.13436C24.0646 9.59027 24.375 11.1507 24.375 12.7266C24.375 15.9092 23.1107 18.9614 20.8603 21.2118C18.6098 23.4623 15.5576 24.7266 12.375 24.7266C5.739 24.7266 0.375 19.3266 0.375 12.7266C0.375 9.54397 1.63928 6.49172 3.88972 4.24128C6.14016 1.99084 9.1924 0.726563 12.375 0.726562ZM12.975 6.72656V13.0266L18.375 16.2306L17.475 17.7066L11.175 13.9266V6.72656H12.975Z" fill="#ED2385"/></svg>'},
    {text:"Creativity",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none"><path d="M10.4889 1.02038C11.9352 1.55153 7.69646 4.16038 10.3457 5.91003C11.8349 6.8942 15.0856 2.44197 16.7181 1.44217C18.1071 0.598594 20.5415 0.426754 21.7731 3.36366C23.3339 7.08166 21.9735 16.7516 16.3458 20.6414C10.6607 24.5469 3.50069 22.5629 1.02332 16.486C-1.85501 9.40932 5.49117 -0.838617 10.4889 1.02038ZM12.2216 11.0965C13.267 13.6585 18.952 10.3154 17.6489 6.72236C16.804 4.39471 11.2335 8.67509 12.2216 11.0965Z" fill="#B2ABCB"/></svg>'},
    {text:"Presentation",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><rect x="5.46631" y="10.7266" width="7" height="10" rx="1" fill="#DED7BE"/><rect x="15.4663" y="3.72656" width="7" height="17" rx="1" fill="#F98C31"/><path d="M22.4663 22.7266L22.4663 24.7266L2.46631 24.7266L2.46631 22.7266L22.4663 22.7266Z" fill="#F98C31"/><path d="M0.466309 23.7266C0.466309 23.1743 0.914024 22.7266 1.46631 22.7266H2.46631V24.7266H1.46631C0.914024 24.7266 0.466309 24.2788 0.466309 23.7266Z" fill="#F98C31"/><path d="M24.4663 23.7266C24.4663 24.2788 24.0186 24.7266 23.4663 24.7266L22.4663 24.7266L22.4663 22.7266L23.4663 22.7266C24.0186 22.7266 24.4663 23.1743 24.4663 23.7266Z" fill="#F98C31"/></svg>'},
    {text:"Leadership",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M8.95868 4.81607C10.479 2.08973 11.2386 0.726562 12.375 0.726562C13.5114 0.726562 14.271 2.08973 15.7913 4.81607L16.1849 5.52165C16.6169 6.29683 16.8329 6.68442 17.1689 6.94002C17.5049 7.19561 17.9249 7.29041 18.7648 7.48L19.528 7.6528C22.48 8.32118 23.9547 8.65478 24.3063 9.78395C24.6567 10.9119 23.6511 12.0891 21.6388 14.4422L21.118 15.0506C20.5468 15.719 20.26 16.0538 20.1316 16.4666C20.0032 16.8806 20.0464 17.327 20.1328 18.2185L20.212 19.0309C20.5156 22.1713 20.668 23.7408 19.7488 24.438C18.8296 25.1364 17.4473 24.4992 14.6849 23.2272L13.9686 22.8984C13.1838 22.536 12.7914 22.3561 12.375 22.3561C11.9586 22.3561 11.5662 22.536 10.7814 22.8984L10.0663 23.2272C7.30272 24.4992 5.92035 25.1352 5.00237 24.4392C4.08199 23.7408 4.23439 22.1713 4.53798 19.0309L4.61718 18.2197C4.70358 17.327 4.74678 16.8806 4.61718 16.4678C4.48998 16.0538 4.20319 15.719 3.63201 15.0518L3.11122 14.4422C1.09887 12.0903 0.0932886 10.9131 0.44368 9.78395C0.795272 8.65478 2.27124 8.31998 5.22317 7.6528L5.98635 7.48C6.82513 7.29041 7.24392 7.19561 7.58111 6.94002C7.9171 6.68442 8.1331 6.29683 8.56509 5.52165L8.95868 4.81607Z" fill="#2AF6FF"/></svg>'},
    {text:"Initiative",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M22.5 22.7266L22.5 24.7266L2.5 24.7266L2.5 22.7266L22.5 22.7266Z" fill="#8760F1"/><path d="M0.5 23.7266C0.5 23.1743 0.947715 22.7266 1.5 22.7266H2.5V24.7266H1.5C0.947715 24.7266 0.5 24.2788 0.5 23.7266Z" fill="#8760F1"/><path d="M24.5 23.7266C24.5 24.2788 24.0523 24.7266 23.5 24.7266L22.5 24.7266L22.5 22.7266L23.5 22.7266C24.0523 22.7266 24.5 23.1743 24.5 23.7266Z" fill="#8760F1"/><path d="M22.5 9.72656H17.25V11.298H19.9425L14.25 17.2616L11.0325 13.883C10.9628 13.8093 10.8798 13.7509 10.7884 13.711C10.697 13.6711 10.599 13.6506 10.5 13.6506C10.401 13.6506 10.303 13.6711 10.2116 13.711C10.1202 13.7509 10.0372 13.8093 9.9675 13.883L4.5 19.6187L5.5575 20.7266L10.5 15.5487L13.7175 18.9273C13.7872 19.0009 13.8702 19.0594 13.9616 19.0993C14.053 19.1392 14.151 19.1597 14.25 19.1597C14.349 19.1597 14.447 19.1392 14.5384 19.0993C14.6298 19.0594 14.7128 19.0009 14.7825 18.9273L21 12.4058V15.2266H22.5V9.72656Z" fill="#C7BEDE"/></svg>'},
    {text:"Adaptability",icon:'<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M9.6875 14.7266L9.5 14.5341L3.9425 8.97656C3.66081 8.69655 3.50167 8.31625 3.5 7.91906V5.22656C3.5 4.82874 3.65804 4.44721 3.93934 4.1659C4.22064 3.8846 4.60218 3.72656 5 3.72656H20C20.3978 3.72656 20.7794 3.8846 21.0607 4.1659C21.342 4.44721 21.5 4.82874 21.5 5.22656V7.91906C21.4983 8.31625 21.3392 8.69655 21.0575 8.97656L15.5 14.5341L15.3125 14.7266H9.6875Z" fill="#23EDD9"/><path d="M9.5 15.7266H15.5V19.7266C15.5 20.8311 14.6046 21.7266 13.5 21.7266H11.5C10.3954 21.7266 9.5 20.8311 9.5 19.7266V15.7266Z" fill="#12BAA9"/></svg>'}
]

const OnboardingPages = (props) => {
    // const [user, setUser] = useState(placeholderUser);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
	const user = useSelector((state) => state.user);
    // const user = placeholderUser

    // TODO: user change not triggering the page render
    console.log(user)

    useEffect(() => {
        if (page === 1) {
            //set loading to true
            setLoading(true)
            //after 3 seconds 
            setTimeout(() => {
                setLoading(false)
                setPage(2)
            },5000)
        }
    },[page])

    return (
        <div className={page === 2 ? "onboarding-container with-character" : "onboarding-container"}>
            <div className="onboarding-content">
                <div className="bot-image">
                    {loading && <div className="loading"/>}
                    <img src={botImage} alt={"Bot"} />
                </div>
                {page === 0 ? 
                    <div className="onboarding-text fadeIn">
                        <h1 className="desktop-h2">Hi {user ? user.firstname : "Bob"}!</h1>
                        <p>I'm your personal career AI coaching assistant and I will assist you in determining a pathway to a career that you can feel good about.</p>
                        <div className="action-row">
                            <button className="button medium primary" onClick={() => setPage(1)}>Begin Journey</button>
                        </div>
                    </div>  
                : page === 1 ? 
                // TODO: set bot to loading
                    <div className="onboarding-text fadeIn">
                        <h1 className="desktop-h2">Creating Your Character</h1>
                        <p>We are generating an avatar based on the profile image you have in your account. Give us a few minutes to get this right.</p>
                        <div className="card school-id">
                            <div className="image">
                                <img src={schoolPhoto} alt="photo"/>
                            </div>
                            <div className="card-body">
                                <h2 className="student-name">{user.firstname} {user.lastname}</h2>
                                <div className="group">
                                    <h3 className="group-label">Level</h3>
                                    <p>{user.grade} grade</p>
                                </div>
                                <div className="group">
                                    <h3 className="group-label">Extracurriculars</h3>
                                    {user.extracurriculars.length > 0 ? <>
                                       { user.extracurriculars.map((item,ind) => {
                                            return (<p key={"ext"+ind}>{item}</p>)
                                        })}
                                        </> : <p>None</p>}
                                    
                                </div>
                            </div>
                        </div>
                        {/* <button className="button medium primary" onClick={() => setPage(1)}>Begin Journey</button> */}
                    </div> 
                : page === 2 ? 
                    <div className="onboarding-text fadeIn">
                        <h1 className="desktop-h2">Here's your avatar</h1>
                        <p>Ready to start exploring? Just a couple of quick questions so we can point you in the right direction.</p>
                        <div className="action-row">
                        <button className="button medium light outline" onClick={() => setPage(1)}>Try another photo</button>
                            <button className="button medium primary" onClick={() => setPage(3)}>Next</button>
                        </div>
                    </div>
                : page === 3 ? 
                <div className="onboarding-text fadeIn">
                    <h1 className="desktop-h2">Your interests</h1>
                    <div className="progress-dots">
                        <div className="dot filled"/>
                        <div className="dot"/>
                    </div>
                    <p>Knowing what you like and what you’re good at can help lead you to discover what careers you might find most fulfilling.</p>
                    <div className="chip-list">
                        {interestSource.map((item, index) => {
                            return (<ToggleChip title={item} key={"interest"+index}/>)
                        })}
                    </div>
                    <div className="action-row">
                        <button className="button medium light outline" onClick={() => navigate("/")}>Skip</button>
                        <button className="button medium primary" onClick={() => setPage(4)}>Next</button>
                    </div>
                </div>
            : page === 4 ? 
                <div className="onboarding-text fadeIn">
                    <h1 className="desktop-h2">Your skills</h1>
                    <div className="progress-dots">
                        <div className="dot"/>
                        <div className="dot filled"/>
                    </div>
                    <p>What skills would you like to showcase or develop further? <br/>Let's get you leveled up and career-ready!</p>
                    <div className="chip-list">
                    {skillSource.map((item, index) => {
                            return (<ToggleChip title={item.text} icon={item.icon} key={"skill"+index}/>)
                        })}
                    </div>
                    <div className="assessment-reminder">
                        <p><a className="link-btn">Take an assessment to gauge what skills you already have.</a></p>
                    </div>
                    <div className="action-row">
                        <button className="button medium light outline" onClick={() => navigate("/")}>Skip</button>
                        <button className="button medium primary" onClick={() => navigate("/")}>Finish</button>
                    </div>
                </div>
            : <></>}
            </div> 
        </div>
    )
}
export default OnboardingPages