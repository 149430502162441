import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchCareers = createAsyncThunk(
    'careers/fetchCareers',
    async (_, { dispatch }) => {
        const headers = new Headers();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/career-clusters`, {
            method: "GET",
            credentials: "include",
            headers: headers,
        });
        const data = await response.json();
        dispatch(setCareers(data));
    }
);

const careersSlice = createSlice({
    name: "careers",
    initialState: {
        data: [],
        isLoading: false,
        error: null
    },
    reducers: {
        resetCareers: (state) => {
            state.data = [];
        },
        setCareers(state, action) {
            state.data = action.payload;
        },
    }
});

export const { resetCareers, setCareers } = careersSlice.actions;
export default careersSlice.reducer;
