import React from "react";
import Header from "../shared/Header";
import OnboardingPages from "./OnboardingPages";

const OnboardingContainer = (props) => {
    return (
        <div className="page-wrapper">
            <Header auth={true}/>
            <main className="onboarding">
                <OnboardingPages />
            </main>
        </div>
    )
}
export default OnboardingContainer