import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/loading.json'

const LoadingIcon = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="lottie-container" style={{
            width: '50px', // Ensures the container takes full width of its parent
        }}>
            <Lottie
                options={defaultOptions}

            />
        </div>
    );
};

export default LoadingIcon;
