import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMessage, clearMessage, addMessageToHistory, setLoading, clearHistory, updateMessageButtons } from '../../redux/store';
import { sendMessage } from "../../util/sendMessage";
import LoadingIcon from "./LoadingIcon";
import handleSend from "../../util/handleSend";
import { updateJobs } from '../../redux/user';  // Adjust the import path according to your project structure
import './chat.css'
import level from '../../assets/images/collab.webp';
import play from '../../assets/icons/play.svg';
import logic from '../../assets/images/video_thumbnail1.png';

import {sendAddJobsRequest} from "../../util/sendAddJobsRequest";
import {fetchGreeting} from "../../util/fetchGreeting";

function MessageArea() {
    const dispatch = useDispatch();
    const message = useSelector(state => state.message);
    const chatHistory = useSelector(state => state.history);
    const loading = useSelector(state => state.loading);
    const messagesEndRef = useRef(null);
    const user = useSelector(state => state.user)
    const currentJobs = useSelector(state => state.user.jobs || []); // Ensure you adjust the path according to your state structure
    const [addedJobs, setAddedJobs] = useState({});  // Tracks the state of each job button
    const [selectedIndices, setSelectedIndices] = useState({}); // Tracks selected state of each button

    const addJob = async (button, chatIndex, buttonIndex) => {
        // Proceed with your add job functionality
        // Assuming you handle adding the job to the user's profile here
        console.log('args',button, chatIndex, buttonIndex)
        dispatch(setLoading(true))
        dispatch(clearHistory());
        const updatedJobs = [...currentJobs, button];
        console.log('30', chatHistory)
        // Dispatch the updated jobs array to the Redux store
        dispatch(updateJobs(updatedJobs));
        // Dispatch the action to remove the button from the chat history
        console.log('34')
        if (chatHistory.length !== 1) {
            dispatch(updateMessageButtons({ messageIndex: chatIndex, buttonIndex: buttonIndex }));
        }
        console.log('one')
        let responseMessage = await sendAddJobsRequest(button)
        console.log('two')

        dispatch(addMessageToHistory({ user: 'Assistant', text:  responseMessage.message.message.content.split('\n').map(line => `<p>${line}</p>`).join('')}));

        dispatch(setLoading(false))

    };

    const handleClick = (chatIndex, btnIndex) => {
        const key = `${chatIndex}-${btnIndex}`; // Create a unique key for each button
        setSelectedIndices(prev => ({
            ...prev,
            [key]: !prev[key]  // Toggle the selected state for the specific button
        }));
    };


    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);


    useEffect(() => {
        fetchGreeting(dispatch);
    }, []);


    return (
        <div className="message-controller">
            {chatHistory?.map((chat, index) => (
                <div className={'actions'} key={index}>
                    <div style={{marginBottom: '20px'}} className={chat.user === 'You' ? 'user-message' : 'assistant-message'} dangerouslySetInnerHTML={{ __html: chat.text }} />
                    {chat.data?.hasButtons && <p style={{margin:'0 40px', marginBottom: '20px'}}>Here are some fast growing jobs in this cluster:</p>}
                    <div style={{
                        margin: '0 30px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyItems: 'space-between',
                        flexWrap: 'wrap',
                    }}>
                        {chat.data?.about && <>
                            <p style={{paddingLeft:'10px', marginBottom:'20px', marginTop: 0}}>Check out how you can level up in this field.</p>
                            <div className={'level-up'} style={{paddingLeft: '10px', maxWidth: '300px'}}>
                                <div className="play-icon">
                                    <img src={play} alt="Play video"/>
                                </div>

                                <img src={logic} alt="Level Indicator"/>

                                <span className={'level-title'}>Careers in automation</span>
                            </div>
                        </>}

                        {chat.data?.hasButtons && chat.data?.buttons && chat.data?.buttons?.map((button, btnIndex) => (
                            addedJobs[button] ?
                                <span key={btnIndex} className="chip-label dark fade-out">Added to your profile</span> :
                                <button
                                    onClick={() => chat.data?.video ? addJob(button, index, btnIndex) : handleClick(index, btnIndex)}  // Adjusted onClick
                                    className={`chip-label ${selectedIndices[`${index}-${btnIndex}`] ? 'light' : 'dark'}`}  // Adjusted className
                                    style={{margin: '5px'}}
                                    key={btnIndex}
                                >
                                    {button}
                                </button>
                        ))}

                        {chat.data?.video && <>
                            <p style={{paddingLeft: '10px', marginBottom: '20px'}}>Check out how you can level up in
                                this field.</p>
                            <div className={'level-up'} style={{paddingLeft: '10px', maxWidth: '300px'}}>
                                <img src={level} alt="Level Indicator"/>

                                <span className={'level-title'}>Collaboration is key</span>
                                <span>Video learning</span>
                                <span>9 min</span>
                            </div>
                        </>}
                        {chat.data?.video &&
                            <p style={{margin: '0 10px', marginTop: '20px'}}>Please rate any of the options you're
                                interested in, and I'll automatically add the ones you like to your 'About Me' section
                                to enhance your profile.:</p>}

                        {chat.data?.aboutButtons && <p style={{marginLeft: '10px', marginBottom: '20px'}}>Here are some similar jobs to consider:</p>}

                        {chat.data?.aboutButtons && chat.data?.buttons && chat.data?.buttons?.map((button, btnIndex) => (
                            addedJobs[button] ?
                                <span key={btnIndex} className="chip-label dark fade-out">Added to your profile</span> :
                                <button
                                    onClick={() => chat.data?.video ? addJob(button, index, btnIndex) : handleClick(index, btnIndex)}  // Adjusted onClick
                                    className={`chip-label ${selectedIndices[`${index}-${btnIndex}`] ? 'light' : 'dark'}`}  // Adjusted className
                                    style={{margin: '5px'}}
                                    key={btnIndex}
                                >
                                    {button}
                                </button>
                        ))}
                        {chat.data?.aboutButtons && <p style={{marginLeft: '10px', marginBottom: '20px'}}>Want to try something else?  I have some ideas...</p>}
                        {chat.data?.aboutButtons && <button
                            // Adjusted onClick
                            className={`action-button`} // Adjusted className
                            key={0}
                            style={{padding:'10px 20px'}}
                        >
                            {"Recommend jobs / specialities"}
                        </button>}

                    </div>
                </div>
            ))}

            <div ref={messagesEndRef}></div>
            {loading ? (
                <div style={{
                    padding: '40px',
                    lineHeight: '1.5',
                    fontSize: '16px',
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <LoadingIcon/>
                </div>
            ) : (
                <div style={{height: '24px'}}></div>
            )}
            <div className="message-type-area">
                <textarea className="gr-input" value={message} onChange={e => dispatch(setMessage(e.target.value))}></textarea>
                <button style={{flex:'none'}} className="action-button" onClick={async () => {
                    try {
                        await handleSend(dispatch, message, 'user', user);
                    } catch (error) {
                        console.error('Failed to send message:', error);
                    }
                }}>New Chat</button>
            </div>
        </div>
    );
}

export default MessageArea;
