import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (id) => {
        const fakeId = '6626e8bf7c1f637c4023b902'; // For faking purposes, ID is always the same
        const headers = new Headers();
        const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${fakeId}`, {
            method: "GET",
            credentials: "include",
            headers: headers,
        });
        const data = await resp.json();
        return { data };
    }
);

const userSlice = createSlice({
    name: "user",
    initialState: { loaded: false },
    reducers: {
        resetUser: (state, action) => {
            state = {};
            return state;
        },
        updateExtracurriculars: (state, action) => {
            // Assuming the state structure is adjusted to hold user data properly
            state.extracurriculars = action.payload;
        },
        updateJobs: (state, action) => {
            state.jobs = action.payload;
        },
        updateCareerClusters: (state, action) => {
            state.careerclusters = action.payload;
        },
        updateRatings: (state, action) => {
            state.ratings = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            return action.payload.data;
        })
            .addCase(fetchUser.pending, (state, action) => {
                return {};
            })
    }
});

// Export the new actions along with the existing ones
export const { resetUser, updateExtracurriculars, updateJobs, updateCareerClusters, updateRatings } = userSlice.actions;

// Export the reducer
export default userSlice.reducer;
