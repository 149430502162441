import React from "react";
import { Link } from "react-router-dom";
import placeholder from "../assets/images/start_placeholder.png";

const Start = () => {

    return (
        <div className="start-page">
            <Link to={"/onboarding"}>
                <img src={placeholder} alt={"Click to get started"}/>
            </Link>
        </div>
    )
}

export default Start