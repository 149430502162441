import React, { useState } from "react";
import HSAvatar from "../assets/images/avatar-highschool.png";
import WHAvatar from "../assets/images/avatar-warehouse.png";
import MFAvatar from "../assets/images/avatar-manufacturing.png";
import { useSelector, useDispatch } from "react-redux";
import { addJob } from "../util/addJob";
import {fetchGrowingJobsAi} from "../util/fetchGrowingJobsAi";
import {prepareModalData} from "../util/prepareModalData";
import {setLoading} from "../redux/store";
import {clearHistory} from "../redux/store";
import {prepareTabModalData} from "../util/prepareTabModalData";
const AboutMe = (props) => {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    async function handleClick (job) {
        // props.openQuest({open: true, quest: job})
        props.changeAvatar(job)
        dispatch(clearHistory())
        dispatch(setLoading(true))
        let data ={}
        data.title = 'Logistics Coordinator'
        await prepareTabModalData(dispatch, data) // prepares the data to send to our chat bot
    }

    return (
        <>
            {
                user.firstname ?
                    <div className={props.avatar.toLowerCase().includes('logistics') ? "about-me-container warehouse" : props.avatar.toLowerCase().includes('engineer') ? "about-me-container manufacturing" : "about-me-container"}>
                <div className="card about-me">
                    <div className="card-header">
                        <h1 className="mobile-h3 bold">Currently exploring</h1>
                    </div>
                    <div className="card-body">
                        <div className="group">
                            <h2 className="desktop-h5 bold">Interests</h2>
                            <div className="chip-list">
                                {/* Map interests */}
                                {
                                    user.extracurriculars.map(interest => (
                                        <div className="chip-label dark">{interest}</div>
                                    ))
                                }
                            </div>
                        </div>
                        
                        <div className="group">
                            <h2 className="desktop-h5 bold">Career Clusters</h2>
                            <div className="chip-list">
                                {/* Map careers */}
                                {
                                    user.careerclusters.map(cluster => {
                                        
                                        return (
                                        <div className={"career-cluster"} title={cluster.name}>
                                            <div className={`image ${cluster.cluster_id}`}>
                                                <img src={"./images/"+cluster.thumbnail} alt=""/>
                                            </div>
                                            <p className="card-title">{cluster.name}</p>
                                        </div>)
                                    })
                                }
                            </div>
                        </div>
                        <div className="group">
                            <h2 className="desktop-h5 bold">Jobs / Specialties</h2>
                            <div className="chip-list">
                                {/* Map jobs, once selected in chat */}
                                {user.jobs.length > 0 ? <>
                                    {user.jobs.map(job => (
                                        <button className={props.avatar === job ? "chip-label dark selected" : "chip-label dark"} onClick={() => handleClick(job)}>{job}</button>
                                    ))}
                                    </> 
                                    : <p className="text-body small" style={{margin:0}}>No jobs selected</p>
                                }
                            </div>
                        </div>
                        <div className="group">
                            <div className="group-header flex-row">
                                <h2 className="mobile-h3 bold">Achievements</h2>
                                <button className="link-btn" onClick={() => props.achievements()}>View all</button>
                            </div>
                            
                            <h3 className="desktop-h5 bold">Skills</h3>
                            <ul className="skills-list">
                                {/* Map skills */}
                                {
                                    user.skills.map(skill => (
                                        <li className="skills-item" key={"skill-"+skill.name.replace(' ',"")}>
                                            <span className="icon" dangerouslySetInnerHTML={{__html: skill.icon }}></span>
                                            <span className="label">{skill.name}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="group">
                            <h3 className="desktop-h5 bold">Badges</h3>
                            <div className="badges-group card-row overflow-scroll">
                            <div className="card achievement-card">
                                <div className="card-info">
                                    <p className="card-title">Get onboard</p>
                                    <p>Complete quiz</p>
                                </div>
                                <div className="image">
                                <img src={"./images/badge-trophy.png"} alt="logo"/>
                                </div>
                            </div>
                            <div className="card achievement-card">
                                <div className="card-info">
                                    <p className="card-title">Career explorer</p>
                                    <p>Watch 5 videos</p>
                                </div>
                                <div className="image">
                                <img src={"./images/badge-trophy.png"} alt="logo"/>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="group">
                            <h3 className="desktop-h5 bold">Assessments</h3>
                            {/* <p className="group-label">2 of 3 completed</p> */}
                            <div className="assessment-group">
                                <div className="card dark assessment-card">
                                    <div className="image"></div>
                                    <p className="card-title">Watson-Glaser™ III</p>
                                </div>
                                <div className="card dark assessment-card">
                                    <div className="image"></div>
                                    <p className="card-title">Work Style Lens (WS-Lens)</p>
                                </div>
                                <div className="card dark assessment-card">
                                    <div className="image">
                                        {/* <div className="progress-circle"></div> */}
                                    </div>
                                    <p className="card-title">In progress assessment</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="avatar">
                    <button>Edit Photo</button>
                    <img src={props.avatar.toLowerCase().includes('logistics') ? WHAvatar : props.avatar.toLowerCase().includes('engineer') ? MFAvatar : HSAvatar} alt="Jason Matthews"/>
                </div>
            </div> :
            <div>Loading...</div>
        }
        </>
    )
}
export default AboutMe