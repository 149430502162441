import React, { useState, useEffect, useRef } from "react";

const Tooltip = (props) => {
    const [message, setMessage] = useState(props.message)
    const toastRef = useRef(null)

    // useEffect(()=>{
    //     return () => {
    //         handleUnmount()
    //       };
    // },[])

    useEffect(()=> {
        // console.log('redux toast: ' + props.message)
		if (props.message && props.message.length !== null) {
			setTimeout(event => {
				handleClose()
			},3000)
		}

	},[props.message])

    const handleClose = () => {
        if(toastRef.current){
            toastRef.current.classList.replace('fadeIn','fadeOut')
            setTimeout(()=>{
                setMessage(null)
            },200)
        }
    }

    const handleUnmount = () => {
        setMessage(null)
    }
    return (
    <div className="tooltip-container fadeIn" role="alert" ref={toastRef}>
        <div className="tooltip">
            <span className="text">{message}</span>
        </div>
    </div>
    )
}
export default Tooltip