// store.js
import { configureStore } from '@reduxjs/toolkit';
import allCareersReducer from './allCareers';
import singleCareerReducer from './singleCareer';
import userReducer from './user';
import screenReducer from './screenState';
import {
    chatReducer,
    messageReducer,
    historyReducer,
    loadingReducer,
    openChat,
    closeChat,
    toggleChat,
    toggleCanType,
    setMessage,
    clearMessage,
    addMessageToHistory,
    setLoading,
    clearHistory,
    updateMessageButtons
} from './allChat';

const store = configureStore({
    reducer: {
        careers : allCareersReducer,
        career : singleCareerReducer,
        user : userReducer,
        modal : screenReducer,
        chat: chatReducer,
        message: messageReducer,
        history: historyReducer,
        loading: loadingReducer,
    }
});

export { openChat, closeChat, toggleChat, toggleCanType, setMessage, clearMessage, addMessageToHistory, setLoading, clearHistory, updateMessageButtons };

export default store;