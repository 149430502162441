const getOverlays = () => {
    const hotspotRatio = 4200;
    const overlays = [
        {
            id : 'cluster1',
            x : 1000/hotspotRatio,
            y : 2000/hotspotRatio,
            className : "drawer-btn cluster1",
            text: 'Agriculture, Food & Natural Resources',
            clusterId: 'CC1',
            width: 315/hotspotRatio,
            height:231/hotspotRatio,
            img: 'hotspot_fishing.png'
        },

        {
            id : 'cluster3',
            x : 2640/hotspotRatio,
            y : 1140/hotspotRatio,
            className : "drawer-btn cluster3",
            text: 'Arts, A/V Technology & Communications',
            clusterId: 'CC3',
            width: 375/hotspotRatio,
            height:275/hotspotRatio,
            img: 'hotspot_arts.png'
        },
        {
            id : 'cluster4',
            x : 675/hotspotRatio,
            y : 1375/hotspotRatio,
            className : "drawer-btn cluster4 recommended",
            text: 'Business Management & Administration',
            clusterId: 'CC4',
            width: 175/hotspotRatio,
            height:425/hotspotRatio,
            img: 'hotspot_business.png'
        },
        {
            id : 'cluster6',
            x : 900/hotspotRatio,
            y : 1000/hotspotRatio,
            className : "drawer-btn cluster6",
            text: 'Finance',
            clusterId: 'CC6',
            width: 200/hotspotRatio,
            height:450/hotspotRatio,
            img: 'hotspot_finance.png'
        },
        {
            id : 'cluster2',
            x : 750/hotspotRatio,
            y : 750/hotspotRatio,
            className : "drawer-btn cluster2",
            text: 'Architecture & Construction',
            clusterId: 'CC2',
            width: 300/hotspotRatio,
            height:300/hotspotRatio,
            img: 'hotspot_architecture.png'
        },
        {
            id : 'cluster7',
            x : 1130/hotspotRatio,
            y : 1340/hotspotRatio,
            className : "drawer-btn cluster7",
            text: 'Government & Public Administration',
            clusterId: 'CC7',
            width: 375/hotspotRatio,
            height:400/hotspotRatio,
            img: 'hotspot_govt.png'
        },
        {
            id : 'cluster8',
            x : 2400/hotspotRatio,
            y : 980/hotspotRatio,
            className : "drawer-btn cluster8",
            text: 'Health Science',
            clusterId: 'CC8',
            width: 200/hotspotRatio,
            height:275/hotspotRatio,
            img: 'hotspot_health.png'
        },
        {
            id : 'cluster9',
            x : 2320/hotspotRatio,
            y : 1470/hotspotRatio,
            className : "drawer-btn cluster9",
            text: 'Hospitality & Tourism',
            clusterId: 'CC9',
            width: 216/hotspotRatio,
            height:234/hotspotRatio,
            img: 'hotspot_hospitality.png'
        },
        {
            id : 'cluster11',
            x : 2000/hotspotRatio,
            y : 1170/hotspotRatio,
            className : "drawer-btn cluster11",
            text: 'Information Technology',
            clusterId: 'CC11',
            width: 225/hotspotRatio,
            height:325/hotspotRatio,
            img: 'hotspot_it.png'
        },
        {
            id : 'cluster14',
            x : 1400/hotspotRatio,
            y : 950/hotspotRatio,
            className : "drawer-btn cluster14",
            text: 'Marketing',
            clusterId: 'CC14',
            width: 1.15*132/hotspotRatio,
            height:1.15*388/hotspotRatio,
            img: 'hotspot_marketing.png'
        },
        {
            id : 'cluster13',
            x : 1500/hotspotRatio,
            y : 700/hotspotRatio,
            className : "drawer-btn cluster13 recommended",
            text: 'Manufacturing',
            clusterId: 'CC13',
            width: 325/hotspotRatio,
            height:325/hotspotRatio,
            img: 'hotspot_manufacturing.png'
        },

        {
            id : 'cluster15',
            x : 1700/hotspotRatio,
            y : 1300/hotspotRatio,
            className : "drawer-btn cluster15",
            text: 'Science, Technology, Engineering & Mathematics',
            clusterId: 'CC15',
            width: 175/hotspotRatio,
            height:300/hotspotRatio,
            img: 'hotspot_stem.png'
        },
        {
            id : 'cluster16',
            x : 1800/hotspotRatio,
            y : 1800/hotspotRatio,
            className : "drawer-btn cluster16",
            text: 'Transportation, Distribution & Logistics',
            clusterId: 'CC16',
            width: 500/hotspotRatio,
            height:450/hotspotRatio,
            img: 'hotspot_transportation.png'
        },
        {
            id : 'cluster17',
            x : 1100/hotspotRatio,
            y : 800/hotspotRatio,
            className : "drawer-btn cluster17",
            text: 'Human Services',
            clusterId: 'CC10',
            width: 175/hotspotRatio,
            height:175/hotspotRatio,
            img: 'hotspot_barber.png'
        },
        {
            id : 'cluster18',
            x : 1900/hotspotRatio,
            y : 700/hotspotRatio,
            className : "drawer-btn cluster18",
            text: 'Human Education & Training',
            clusterId: 'CC5',
            width: 425/hotspotRatio,
            height:425/hotspotRatio,
            img: 'hotspot_recreation.png'
        },
        {
            id : 'cluster19',
            x : 2550/hotspotRatio,
            y : 750/hotspotRatio,
            className : "drawer-btn cluster19",
            text: 'Law, Public Safety, Corrections & Security',
            clusterId: 'CC12',
            width: 300/hotspotRatio,
            height:300/hotspotRatio,
            img: 'hotspot_corrections.png'
        },
    ];

    return overlays;
};

export default getOverlays;
