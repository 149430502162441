// allReducers.js
import { createSlice } from '@reduxjs/toolkit';

// Chat slice to manage the state of the chat interface.
const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        isOpen: true, // Boolean to track if the chat is open.
        canType: true, // Boolean to determine if the user can type into the chat.,
        mapData: {}
    },
    reducers: {
        openChat: (state) => {
            state.isOpen = true; // Action to open the chat.
        },
        closeChat: (state) => {
            state.isOpen = false; // Action to close the chat.
        },
        toggleChat: (state) => {
            state.isOpen = !state.isOpen; // Toggles the chat open/close.
        },
        toggleCanType: (state) => {
            state.canType = !state.canType; // Toggles the ability to type messages.
        },
        addOrUpdateMapData: (state, action) => {
            const { id, title } = action.payload;
            state.mapData[id] = title; // Adds or updates map data by ID.
        },
        removeMapData: (state, action) => {
            delete state.mapData[action.payload]; // Removes map data by ID.
        },
    },
});

// Message slice for handling the current message being typed.
const messageSlice = createSlice({
    name: 'message',
    initialState: '',
    reducers: {
        setMessage: (state, action) => action.payload, // Sets the current message to the payload.
        clearMessage: () => '', // Clears the current message.
    },
});

// History slice to maintain the chat history.
const historySlice = createSlice({
    name: 'history',
    initialState: [],
    reducers: {
        addMessageToHistory: (state, action) => {
            state.push(action.payload); // Adds a message to the chat history.
        },
        clearHistory: state => {
            return []; // Clears the entire chat history.
        },
        updateMessageButtons: (state, action) => {
            const { messageIndex, buttonIndex } = action.payload;
            const message = state[messageIndex];
            if (message?.data) {
                message.data.buttons = message.data?.buttons.filter((_, index) => index !== buttonIndex);
            }

        }
    },
});

// Loading slice to track the loading status during async operations.
const loadingSlice = createSlice({
    name: 'loading',
    initialState: false,
    reducers: {
        setLoading: (state, action) => action.payload, // Sets the loading state.
    },
});

export const { openChat, closeChat, toggleChat, toggleCanType,addOrUpdateMapData, removeMapData  } = chatSlice.actions;
export const { setMessage, clearMessage } = messageSlice.actions;
export const { addMessageToHistory, clearHistory, updateMessageButtons } = historySlice.actions;
export const { setLoading } = loadingSlice.actions;

export const chatReducer = chatSlice.reducer;
export const messageReducer = messageSlice.reducer;
export const historyReducer = historySlice.reducer;
export const loadingReducer = loadingSlice.reducer;