import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import "./assets/styles/styles.css";
import MainPage from './components/Main';
import Start from './components/Start';
import OnboardingContainer from './components/onboarding/OnboardingContainer';
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from './redux/user';
import {fetchCareers} from "./redux/allCareers";

function App(props) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const careers = useSelector(state => state.careers.data); // Accessing the data array


	useEffect(() => {
		if(user.loaded === false){
			dispatch(fetchUser());
		}
	}, [dispatch]);


return (
    <BrowserRouter
			props={props}
			basename={process.env.PUBLIC_URL}
		>
			<Routes>
				<Route
					path='/'
					element={<MainPage active={0} />}
				></Route>
        <Route
					path='/map'
					element={<MainPage active={0} />}
				></Route>
        <Route
					path='/profile'
					element={<MainPage active={1} />}
				></Route>
        <Route
					path='/progress'
					element={<MainPage active={2} />}
				></Route>
				<Route
					path='/start'
					element={<Start />}
				></Route>
				<Route
					path='/onboarding'
					element={<OnboardingContainer />}
				></Route>

				
			</Routes>
		</BrowserRouter>

  );
}

export default App;
