import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import Progress from "../../Progress";
import { useSelector } from "react-redux";
import trophy from "../../../assets/images/trophy.svg";


const AchievementsModal = (props) => {
    const user = useSelector(state => state.user);

    console.log(user.jobs.length)
    // const [rating, setRating] = useState('');
    // const dispatch = useDispatch()
    // const career = useSelector((state) => state.career)

    // useEffect(() => {
    //    if(!career.name || career.cluster_id !== props.data.id){
    //      dispatch(fetchSingleCareer(props.data.id))
    //    }

    //    return () => {
    //     resetCareer()
    //    }
    // },[dispatch])

    return (
        <div className="modal-overlay transparent">
            <div className="modal career-modal achievements-modal">
                <button className="button icon-btn close-btn" aria-label="close" onClick={() => props.close()}>
                        <Icon icon="close" size="24"/>
                </button>
                {/* <div className="modal-header">
                    
                    
                </div> */}
                <div className="modal-body">
                <h1 className="desktop-h3 bold">Achievements</h1>
                    {/* <section>
                        <div className="progress-bar-container">
                            <div className="progress-bar">
                                <div className="bar" style={{width:"12%"}}></div>
                            </div>
                            <p className="body-text small progress-bar-label">Overall PVS Exploration: 12% complete</p>
                        </div>
                    </section> */}
                    <section>
                        <div className="card horizontal-card">
                            <div class="avatar" style={{borderRadius:0,background:"transparent"}}>
                                <img src="/images/badge-career-discovery.png" alt="logo"/>
                            </div>
                            <div className="card-body">
                                <h3 className="card-title desktop-h5 bold">Congrats on your first badge!</h3>
                                <p className="body-text small">Career explorer: Watch 5 videos</p>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h2 className="desktop-h5 bold" style={{marginTop:0,marginBottom:"-4px"}}>In progress</h2>
                        <div className="card-row overflow-scroll">
                            <div className="card achievement-card">
                                <div className="card-info">
                                    <p className="card-title">Career Discovery</p>
                                    <p className="progress">1/2 complete</p>
                                </div>
                                <div className="image">
                                    <img src="/images/badge-blank.png" alt="logo"/>
                                </div>
                            </div>
                            <div className="card achievement-card">
                                <div className="card-info">
                                    <p className="card-title">Rate clusters</p>
                                    <p className="progress">7/16 complete</p>
                                </div>
                                <div className="image">
                                    <img src={"./images/badge-trophy.png"} alt="logo"/>
                                </div>
                            </div>
                            {user.jobs.length > 0 ? <>
                                {user.jobs.map(item => {
                                    return (<button className="card achievement-card" onClick={() => props.openQuest({open:true, quest:item})}>
                                        <div className="card-info">
                                            <p className="card-title">Pathway</p>
                                            <p className="progress">{item}</p>
                                        </div>
                                        {item.toLowerCase() === 'manufacturing engineer' ? 
                                                <span className="image CC13">
                                                    <img src={"./images/badge-manufacturing.png"} alt="manufacturing"/>
                                                </span>

                                                : <span className="image CC4">
                                                    <img src={"./images/badge-business.png"} alt="business"/>
                                                </span>
                                            }
                                        
                                    </button>)
                                })}
                            </> : <></>}
                        </div>

                    </section>
                    <section>
                        <h2 className="desktop-h5 bold" style={{marginTop:0,marginBottom:"-4px"}}>Completed</h2>
                        <div className="card-row overflow-scroll">
                            <div className="card achievement-card">
                                <div className="card-info">
                                    <p className="card-title">Get onboard</p>
                                    <p>Complete quiz</p>
                                </div>
                                <div className="image">
                                <img src={"./images/badge-trophy.png"} alt="logo"/>
                                </div>
                            </div>
                            <div className="card achievement-card">
                                <div className="card-info">
                                    <p className="card-title">Career explorer</p>
                                    <p>Watch 5 videos</p>
                                </div>
                                <div className="image">
                                <img src={"./images/badge-trophy.png"} alt="logo"/>
                                </div>
                            </div>
                        </div>

                    </section>
                    <section>
                        <p className="body-text small">Overall Exploration: 12</p>
                    </section>
                </div>
            </div>
        </div>
    )
}
export default AchievementsModal