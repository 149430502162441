import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { AUTH_BASE_URL } from "./config";

export const fetchSingleCareer = createAsyncThunk(
  'career/fetchSingleCareer',
  async (id) => {
      const headers = new Headers()
      const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/career-clusters/${id}`,
      { 
        method : "GET", 
        credentials : "include",
        headers : headers,
      })
      const data = await resp.json()
      return { data }
  }
)

const singleCareerSlice = createSlice({
    name: "career",
    initialState: {},
    reducers : {
      resetCareer : (state, action) => {
        return {}
      }
    },
    extraReducers: (builder) => {
      builder.addCase(fetchSingleCareer.fulfilled, (state, action) => {
          return action.payload.data
      })
      builder.addCase(fetchSingleCareer.pending, (state, action) => {
          return []
      })
  }
});
  
  // Note: skillsSlice.actions automatically creates actions based on our reducer names
  export const { resetCareer } = singleCareerSlice.actions;
  
  // export  reducer from todoSlice
  export default singleCareerSlice.reducer;