import React, {useState} from "react";

const ToggleChip = (props) => {
    const [checked, setChecked] = useState(false)
    return (
        <button className="chip-btn dark toggle-chip" aria-pressed={checked} onClick={() => setChecked(!checked)}>
            {props.icon && <span className="icon" dangerouslySetInnerHTML={{__html: props.icon}}/> }
            {props.title}
        </button>
    )
}

export default ToggleChip