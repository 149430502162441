import React, {useState, useEffect} from "react";
import OpenSeadragon from "openseadragon";
import {useDispatch, useSelector} from "react-redux";

import {prepareModalData} from "../util/prepareModalData";
import getOverlays from "../assets/map/overlayData";
import {fetchCareers} from "../redux/allCareers";
import {} from "../redux/singleCareer";
import {clearHistory} from "../redux/allChat";
import {setLoading} from "../redux/allChat";

export default function Map(props) {
    const dispatch = useDispatch();
    const canType = useSelector(state => state.chat.canType);
    const careers = useSelector(state => state.careers.data);

    function openModal(type) {

        dispatch(setLoading(true))
        prepareModalData(dispatch, type, canType, careers,) // prepares the data to send to our chat bot
        props.openModal(type)
    }

    useEffect(() => {
        dispatch(fetchCareers());
    }, [dispatch]);

    const overlays = getOverlays()

    useEffect(() => {

    let viewer = OpenSeadragon({
        id: 'seadragon-viewer',
        prefixUrl: process.env.PUBLIC_URL + '/images',
        tileSources: process.env.PUBLIC_URL + '/images/MapBase-nobuildings-jpg.dzi',
        showFullPageControl : false,
      autoHideControls : false,
      navigationControlAnchor: OpenSeadragon.ControlAnchor.BOTTOM_LEFT,
        homeFillsViewer: true,
        constrainDuringPan: true,
        visibilityRatio: 1,
        minZoomLevel: 1,
        defaultZoomLevel: 1.1,
        gestureSettingsMouse: {
            clickToZoom: false,
            dblClickToZoom: true
          },
          zoomInButton: "zoomIn",
          zoomOutButton: "zoomOut"
          
      });
      //TODO: constrain map so you don't see any non-map on zoom out/pan AND on load (check other viewports as well)
      //TODO: Fix bug - if click&drag on a hotspot, the modal will open
      //TODO: disable zoom buttons when reach min/max?
      viewer.addHandler('open', () => {
        viewer.viewport.panBy({x:0.02,y:0})
        //TODO: pan back to zero if chat closed

        overlays.forEach(overlay => {

            const getRating = () => {
                if (props.ratings) {
                    
                    let overlayItem = props.ratings.filter(f => f.cluster === overlay.clusterId);

                    if (overlayItem.length > 0) {
                        return overlayItem[0].rating
                    } else {
                        return ""
                    }
                } else {
                    return ''
                }
            }

            var elt = document.createElement("button");
            elt.className = overlay.className;
            elt.id = overlay.id
            elt.setAttribute("data-cluster",overlay.clusterId)
            elt.setAttribute("aria-label",overlay.text)
            if (props.ratings) {elt.setAttribute('data-rating',getRating())}
            if (overlay.img) {elt.innerHTML = '<img src="'+process.env.PUBLIC_URL + '/images/'+overlay.img+'" alt=""/>'}
            viewer.addOverlay({
                element: elt,
                location: new OpenSeadragon.Rect(overlay.x,overlay.y,overlay.width,overlay.height),
                rotationMode: OpenSeadragon.OverlayRotationMode.BOUNDING_BOX
            });
            new OpenSeadragon.MouseTracker({
                element: elt,
                clickHandler: e => openModal({id: overlay.clusterId, title:overlay.text, blurb:overlay.blurb})
                // contextMenuHandler: e => alert('Context menu fired! ' + e.originalEvent.target.id),
            });

        })

       
      
            
        
    });
      // Cleanup (equal to componentWillUnmount)
      return () => {
        viewer.destroy();
        viewer = null;
      };
    }, []);
    

    useEffect(() => {
        //manually add class
        console.log(props.ratings)
        if (props.ratings) {
            props.ratings.forEach(rating => {
                let element = document.querySelector("[data-cluster="+rating.cluster+"]")
                if (element) {
                    document.querySelector("[data-cluster="+rating.cluster+"]").setAttribute('data-rating',rating.rating)
                }            
            })
        }
    },[props.ratings])
   
    return (
        <div id="seadragon-viewer" className="seadragon-viewer" style={{ height: '100%', width: '100%', position: "relative" }} />
    )
}
