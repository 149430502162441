import {addMessageToHistory, clearHistory, setLoading} from "../redux/allChat";

export const fetchAboutMe = async (dispatch) => {
    try {
        dispatch(setLoading(true));
        dispatch(clearHistory());
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/chat/about`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        dispatch(addMessageToHistory({ user: 'Assistant', text: data?.message.split('\n').map(line => `<p>${line}</p>`).join('')}));
    } catch (error) {
        console.error('Failed to send message:', error);
    } finally {
        dispatch(setLoading(false));
    }
};