import { addMessageToHistory, clearMessage, setLoading, setMessage, toggleCanType } from "../redux/store";
import { updateExtracurriculars, updateJobs } from "../redux/user";
import { sendMessage } from "./sendMessage";

export const handleSend = async (dispatch, message, sender, user) => {
    let userInterests = user?.extracurriculars;
    let userJobs = user?.jobs
    if (sender === 'user') {
        if (!message.trim()) return;
        dispatch(setLoading(true));
        dispatch(setMessage(''));
        dispatch(addMessageToHistory({ user: 'You', text: message }));
        try {
            const response = await sendMessage(message);
            if (response.message) {
                dispatch(addMessageToHistory({ user: 'Assistant', text: response.message }));

                // Check if the response type is 'interests'
                if (response.type === 'interests') {
                    // Assume response.data is an array of interests
                    const newUserInterests = [...userInterests, ...response.data];
                    // Dispatch the update to extracurriculars with new data
                    dispatch(updateExtracurriculars(newUserInterests));
                }
                if (response.type === 'jobs') {
                    // Assume response.data is an array of interests
                    const newJobsData = [...userJobs, ...response.data];
                    // Dispatch the update to extracurriculars with new data
                    dispatch(updateJobs(newJobsData));
                }
                return response.data;
            } else {
                console.error('Response data is undefined');
            }
        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            dispatch(setLoading(false));
            dispatch(clearMessage());
        }
    }
    if (sender === 'system') {
        // TODO - Add system message handling with buttons.  this does not go to GPT.
        dispatch(setLoading(false))
        dispatch(addMessageToHistory({ user: 'Assistant', text: message.message, data: message }));
    }
};

export default handleSend;
