export async function fetchAiBlurb(title) {
    const encodedTitle = encodeURIComponent(title);
    const url = `${process.env.REACT_APP_BACKEND_URL}/chat/blurb/${encodedTitle}`;

    try {
        const response = await fetch(url, {
            method: 'POST', // Ensuring it's a POST request
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch AI blurb:', error);
        return null;
    }
}